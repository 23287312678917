import React,{useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import GetPassword from './pages/GetPassword';
import AppointmentsComplete from './pages/AppointmentsComplete';
import Error from './pages/Error';
import axios from 'axios'


function App(){
  const [backgroundImage, setBackgroundImage] = useState('');
  useEffect(()=>{
    const fetchBackgroundImage = async()=>{
      try {
        const response = await axios.get('https://urlarandevubackend.bilcom.com.tr/api/images?populate=*');
        const layoutImage = response.data.data.find(item=> item.imageName==='layoutImage');
        if(layoutImage){
          const imageUrl = layoutImage.images[0].formats.large ? layoutImage.images[0].formats.large.url : layoutImage.images[0].formats.medium.url;
          setBackgroundImage(`https://urlarandevubackend.bilcom.com.tr${imageUrl}`)

        }
      } catch (error) {
        console.log("Error fetching background image:",error);
      }
    }
    fetchBackgroundImage();
  },[])
  useEffect(()=>{
    
    const interval = setInterval(async()=>{
      try {
        const response = await axios.get('https://urlarandevubackend.bilcom.com.tr/api/appointments/move-past-appointments');
        console.log("Apı response", response.data);
      } catch (error) {
        console.log("Error",error);
      }
    },600000);
    return ()=> clearInterval(interval);
  },[])
  return(
    <Router>
      <div  className="min-h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImage})` }}>

      
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path = "/get-password" element={<GetPassword/>} />
        <Route path="/appointment-complete" element ={<AppointmentsComplete />} />
        <Route path="/error" element={<Error/>} />
      </Routes>
      </div>
    </Router>
  )
}
export default App;
import React from "react";
import { FaCircleCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function GetPassword() {
   const navigate = useNavigate();
  return (
    <div className="pt-10">
      <div className="mx-44 bg-layoutBackground bg-opacity-80 flex flex-col gap-5 items-center rounded-md p-2">
        <FaCircleCheck className="text-6xl" color="green" />
        <div className="text-2xl">
          Kaydınız Başarıyla Tamamlanmıştır. Şifrenizi Almak İçin Lütfen Girmiş
          Olduğunuz Mail Adresini Kontrol Ediniz.
        </div>
        <div className="text-blue-500 cursor-pointer" onClick={()=> navigate('/')}>
            Ana sayfaya dönmek için tıklayınız..
        </div>
      </div>
    </div>
  );
}

export default GetPassword;

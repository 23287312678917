import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const CourtsList = ({ phoneNumber }) => {
  const [courts, setCourts] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [appointments, setAppointments] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchCourts = async () => {
      try {
        const response = await axios.get(
          "https://urlarandevubackend.bilcom.com.tr/api/courts?populate=*"
        );

        const updatedCourts = response.data.data.map((court) => {
          const currentDate = new Date().getTime();
          const activeDate = court.courtActiveDateRange
            ? new Date(court.courtActiveDateRange).getTime()
            : null;
          const disabledDate = court.courtDisabledDateRange
            ? new Date(court.courtDisabledDateRange).getTime()
            : null;
          console.log("Court:", court.courtName);
          console.log("Active Date (milisaniye):", activeDate);
          console.log("Disabled Date (milisaniye):", disabledDate);
          console.log("Current Date (milisaniye):", currentDate);

          if (!activeDate || !disabledDate) {
            court.courtStatus = true;
            console.log(
              "No active or disabled date, status set to true (active)"
            );
          } else if (currentDate >= activeDate && currentDate <= disabledDate) {
            court.courtStatus = false;
            console.log(
              "Current date is within active and disabled date range, status set to false (disabled)"
            );
          } else {
            court.courtStatus = true;
            console.log(
              "Current date is outside of date range, status set to true (active)"
            );
          }

          console.log("Final court status:", court.courtStatus);
          return court;
        });

        setCourts(updatedCourts);
      } catch (error) {
        console.error("Error fetching courts:", error);
      }
    };

    const fetchAppointments = async () => {
      try {
        const response = await axios.get(
          "https://urlarandevubackend.bilcom.com.tr/api/appointments"
        );
        setAppointments(response.data);
      } catch (error) {
        console.log("Error", error);
      }
    };

    fetchCourts();
    fetchAppointments();
  }, []);

  const filterAvailableTimes = (courtName, day, times) => {
    console.log(
      `Filtering times for Court ID: ${courtName}, Day: ${day}, Time : ${times}`
    );
    console.log("Appointments:", appointments);
    return times.filter((time) => {
      const isDisabled = appointments.some(
        (appointment) =>
          appointment.courtName === courtName &&
          appointment.courtDay === day &&
          appointment.courtTime === time
      );
      if (isDisabled) {
        console.log(`Filtered out: ${time}`);
      } else {
        console.log("filtrelenecek öğe yok");
      }
      return !isDisabled;
    });
  };

  const getFutureDays = (courtDays) => {
    const todayIndex = new Date().getDay();
    const daysOfWeek = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];
    const sortedCourtDays = courtDays.sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b));
    return sortedCourtDays.filter((day) => {
      const dayIndex = daysOfWeek.indexOf(day);
      return dayIndex >= todayIndex || dayIndex === 0;
    });
  };
  

  const handleDayChange = (e, court) => {
    const selectedDayInfo = court.courtsInformation.find(
      (info) => info.courtDay === e.target.value
    );
    setSelectedDay({
      courtId: court.id,
      courtName: court.courtName,
      day: e.target.value,
      ...selectedDayInfo,
    });

    if (selectedDayInfo) {
      const startTime = parseInt(
        selectedDayInfo.courtStartTime.split(":")[0],
        10
      );
      const endTime = parseInt(selectedDayInfo.courtEndTime.split(":")[0], 10);
      const times = [];
      for (let hour = startTime; hour <= endTime; hour++) {
        times.push(`${hour}:00:00.000`);
      }

      const now = new Date();
      const currentHour = now.getHours();
      const selectedDay = new Date().toLocaleDateString("tr-TR", {
        weekday: "long",
      });

      const filteredTimes = times.filter((time) => {
        const hour = parseInt(time.split(":")[0], 10);
        if (e.target.value === selectedDay) {
          return hour > currentHour;
        }
        return true;
      });

      const availableTimes = filterAvailableTimes(
        court.courtName,
        e.target.value,
        filteredTimes
      );

      setAvailableTimes(availableTimes);
      if(availableTimes.length > 0){
        setSelectedTime(availableTimes[0]);
      }else{
        setSelectedTime("");
      }
      console.log(
        `Selected Day: ${e.target.value}, Available Times: ${availableTimes}`
      );
    } else {
      setAvailableTimes([]);
    }
  };

  const checkExistingAppointment = async (phoneNumber, courtDay) => {
    try {
      const response = await axios.get(
        `https://urlarandevubackend.bilcom.com.tr/api/appointments?phoneNumber=${phoneNumber}&courtDay=${courtDay}`
      );
      return response.data.length > 0;
    } catch (error) {
      console.error("Error checking existing appointment:", error);
      return false;
    }
  };

  const handleAppointmentSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log("Phone number:", phoneNumber);
      const userResponse = await axios.get(
        `https://urlarandevubackend.bilcom.com.tr/api/users?filters[phoneNumber][$eq]=${phoneNumber}`
      );
      console.log("User response:", userResponse.data);

      const users = userResponse.data.data || userResponse.data;
      console.log("Users:", users);

      users.forEach((u, index) => {
        console.log(`User ${index}:`, u);
      });

      const user = users.find((u) => u.phoneNumber === phoneNumber);
      console.log("Found user:", user);

      if (!user) {
        alert("Kullanıcı bulunamadı.");
        return;
      }

      console.log("Selected user:", user);
      const hasExistingAppointment = await checkExistingAppointment(
        user.phoneNumber,
        selectedDay.day
      );
      if (hasExistingAppointment) {
        Swal.fire("Bugün için randevunuz var!");
        return;
      }
      console.log("kişi tel no ", phoneNumber);
      console.log("kişinin seçtiği gün", selectedDay.day);

      const formattedCourtTime = `${selectedTime}`;
      console.log("formatlanmış", formattedCourtTime);

      const response = await axios.post(
        "https://urlarandevubackend.bilcom.com.tr/api/appointments/create",
        {
          data: {
            name: user.name,
            surname: user.surname,
            phoneNumber: user.phoneNumber,
            courtName: selectedDay.courtName,
            courtDay: selectedDay.day,
            courtTime: formattedCourtTime,
          },
        }
      );

      if (response.status === 200) {
        navigate("/appointment-complete");
      }
    } catch (error) {
      console.log("There was an error in appointment", error);
      if (error.response) {
        console.log("Error response data:", error.response.data);
      }
    }
  };

  return (
    <div className="bg-layoutBackground bg-opacity-80 p-5 rounded-md mt-7">
      <h1 className="text-2xl font-bold my-4">KORTLAR LİSTESİ</h1>
      <h3>Lütfen gelmeyeceğiniz randevuları iptal ediniz. Aksi takdirde 7 gün boyunca randevu alamayacaksınız.</h3>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {courts.length > 0 ? (
          courts
            .filter((court) => court.courtStatus === true)
            .map((court) => (
              <div key={court.id} className="bg-white mt-2 p-4 border rounded-md shadow-md">
                <h2 className="text-xl font-semibold">{court.courtName}</h2>
                <p>
                  <strong>Adres:</strong> {court.courtAdress}
                </p>

                <div className="mt-4">
                  <label
                    htmlFor={`court-day-${court.id}`}
                    className="block mb-2 font-medium"
                  >
                    Gün Seçiniz
                  </label>
                  <select
                    id={`court-day-${court.id}`}
                    className="p-2 border rounded-md w-full"
                    onChange={(e) => handleDayChange(e, court)}
                  >
                    <option value="">Gün Seçiniz</option>
                    {getFutureDays(
                      court.courtsInformation.map((info) => info.courtDay)
                    ).map((day, index) => (
                      <option key={index} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                </div>
                {selectedDay.courtId === court.id &&
                  availableTimes.length > 0 && (
                    <div className="mt-4">
                      <label
                        htmlFor={`court-time-${court.id}`}
                        className="block mb-2 font-medium"
                      >
                        Saat Seçiniz
                      </label>
                      <select
                        id={`court-time-${court.id}`}
                        className="p-2 border rounded-md w-full"
                        onChange={(e) => setSelectedTime(e.target.value)}
                      >
                        {availableTimes.map((time) => (
                          <option key={time} value={time}>
                            {time.split(":").slice(0, 2).join(":")}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
              </div>
            ))
        ) : (
          <p>Hiç kort bulunamadı.</p>
        )}
      </div>

      <form onSubmit={handleAppointmentSubmit}>
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-4"
        >
          Randevu Oluştur
        </button>
      </form>
    </div>
  );
};

export default CourtsList;

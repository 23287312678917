import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CourtsList from "../components/CourtsList";
import AppointmentsList from "../components/AppointmentsList";
import CancelAppointment from "../components/CancelAppointment";
import ImageSlider from "../components/ImageSlider";
import Footer from "../components/Footer";
import Swal from "sweetalert2";

function Home() {
  const [inputs, setInputs] = useState([]);
  const [formData, setFormData] = useState({});
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [password, setPassword] = useState("");
  const [showCourtList, setShowCourtList] = useState(false);
  const [showAppointmentList, setShowAppointmentList] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [showCancelComponent, setShowCancelComponent] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://urlarandevubackend.bilcom.com.tr/api/inputs"
        );
        setInputs(response.data.data);
      } catch (error) {
        console.error("There was an error fetching the data!", error);
      }
    };

    const fetchBackgroundImage = async () => {
      try {
        const response = await axios.get(
          "https://urlarandevubackend.bilcom.com.tr/api/images?populate=*"
        );
        const layoutImage = response.data.data.find(
          (item) => item.imageName === "layoutImage"
        );
        if (layoutImage) {
          const imageUrl = layoutImage.images[0].formats.large
            ? layoutImage.images[0].formats.large.url
            : layoutImage.images[0].formats.medium.url;
          setBackgroundImage(
            `https://urlarandevubackend.bilcom.com.tr${imageUrl}`
          );
        }
      } catch (error) {
        console.log("Error fetching background image:", error);
      }
    };
    fetchBackgroundImage();

    fetchData();
  }, []);

  const handlePhoneChange = async (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    if (value.length === 11) {
      try {
        const blackListResponse = await axios.get(
          `https://urlarandevubackend.bilcom.com.tr/api/black-lists?filters[phoneNumber][$eq]=${value}`
        );
        if (blackListResponse.data.data.length > 0) {
          Swal.fire({
            icon: "error",
            title: "İşleminiz İptal Oldu!",
            text: "Daha önce randevu iptali yapmadığınız için 7 günden önce yeni randevu alamazsınız! Lütfen gelmeyeceğiniz zaman randevu iptalinizi yapınız.",
          });

          setShowPasswordInput(false);
          setShowRegisterForm(false);
          setShowAppointmentList(false);
          return;
        }

        const response = await axios.post(
          "https://urlarandevubackend.bilcom.com.tr/api/checkPhoneNumber",
          { phoneNumber: value }
        );

        if (response.data.exists) {
          setShowPasswordInput(true);
          setShowRegisterForm(false);
          setShowAppointmentList(false);
        } else {
          setShowRegisterForm(true);
          setShowPasswordInput(false);
          setShowAppointmentList(false);
        }
        const appointmentResponse = await axios.get(
          `https://urlarandevubackend.bilcom.com.tr/api/appointments?phoneNumber=${value}`
        );
        if (appointmentResponse.data.length > 0) {
          setAppointments(appointmentResponse.data);
        } else {
          setAppointments([]);
        }
      } catch (error) {
        console.error("Error", error);
      }
    }
  };
  const handleCancelAppointment = () => {
    setShowCancelComponent(true);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://urlarandevubackend.bilcom.com.tr/api/checkPassword",
        { phoneNumber, password }
      );

      if (response.data.isPasswordCorrect) {
        const userResponse = await axios.get(
          `https://urlarandevubackend.bilcom.com.tr/api/users?filters[phoneNumber][$eq]=${phoneNumber}&populate=role`
        );
        const user = userResponse.data[0];
        if (user) {
          const userRole = user.role;
          console.log("kişinin rol idsi", userRole.id);
          if (userRole && userRole.id === 4) {
            console.log("randebu kısmı");
            setShowAppointmentList(true);
            setShowCancelComponent(false);
            setShowCourtList(false);
          } else {
            console.log("kort kısmı");
            setShowCourtList(true);
            setShowCancelComponent(false);
            setShowAppointmentList(false);
          }
        }
      } else {
        console.log("Şifre hatalı");
      }
    } catch (error) {
      console.log("Error hatası", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      console.error("Email is required");
      return;
    }
    try {
      const existingUserResponse = await axios.get(
        `https://urlarandevubackend.bilcom.com.tr/api/users?filters[$or][0][email][$eq]=${formData.email}&filters[$or][1][phoneNumber][$eq]=${formData.phoneNumber}`
      );
      if (existingUserResponse.data.length > 0) {
        Swal.fire("Önceden kayıtlı hesabınız bulunmaktadır!");

        return;
      }
      const response = await axios.post(
        "https://urlarandevubackend.bilcom.com.tr/api/sendEmailConfirmation",
        formData
      );
      console.log("Email confirmation sent:", response.data);
      navigate("/get-password");
    } catch (error) {
      console.error(
        "There was an error sending the email confirmation!",
        error
      );
    }
  };

  return (
    <div className="gap-20  sm:mx-44 md:mx-20">
      <div className="pt-10">
        <div className="p-5 bg-layoutBackground bg-opacity-80 rounded-md">
          <h1 className="text-2xl font-bold mb-4">
            URLA BELEDİYESİ TENİS KORTLARI RANDEVU FORMU
          </h1>

          <div className="flex flex-col">
            <label className="sm:mb-2 text-lg font-medium ">
              Telefon Numarası
            </label>
            <input
              type="text"
              className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={phoneNumber}
              onChange={handlePhoneChange}
              maxLength="11"
            />
          </div>

          {showPasswordInput && (
            <form onSubmit={handlePasswordSubmit}>
              <div className="flex flex-col mt-4">
                <label className="mb-2 text-lg font-medium">Parolanız</label>
                <input
                  type="password"
                  className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-4"
              >
                Randevu Saatlerini Gör
              </button>
            </form>
          )}
          {appointments.length > 0 && (
            <div className="mt-4">
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                onClick={handleCancelAppointment}
              >
                Randevu İptal
              </button>
            </div>
          )}
        </div>
        {showCancelComponent && (
          <CancelAppointment
            appointments={appointments}
            setAppointments={setAppointments}
          />
        )}
        {showAppointmentList && <AppointmentsList />}
        {showRegisterForm && (
          <div className="mt-7 p-5 bg-layoutBackground rounded-md">
            <h1>Hesabınız Bulunmamakta. Lütfen Önce Kayıt Yapınız</h1>
            <h1 className="text-2xl font-bold mb-4">KAYIT FORMU</h1>
            <h3 className="mb-4">
              Lütfen bilgileri eksiksiz ve doğru doldurunuz.
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              {(() => {
                // inputs dizisini istediğiniz sıraya göre yeniden düzenliyoruz
                const reorderedInputs = (inputs) => {
                  const adInput = inputs.find((input) => input.id === 15); // Ad girdisi
                  const soyadInput = inputs.find((input) => input.id === 18); // Soyad girdisi
                  const remainingInputs = inputs.filter(
                    (input) => input.id !== 15 && input.id !== 18
                  ); // Geri kalanlar
                  return [adInput, soyadInput, ...remainingInputs];
                };

                const sortedInputs = reorderedInputs(inputs); // Diziyi sıralıyoruz
                return sortedInputs.map((input) => (
                  <div key={input.id} className="flex flex-col">
                    <label className="mb-2 text-lg font-medium">
                      {input.labelName}
                    </label>
                    <input
                      type={input.inputType}
                      name={input.inputName}
                      onChange={handleChange}
                      className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                ));
              })()}
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Kayıt Ol
              </button>
            </form>
          </div>
        )}

        {/* {showRegisterForm && (
          <div className="mt-7 p-5 bg-layoutBackground rounded-md">
            <h1>Hesabınız Bulunmamakta. Lütfen Önce Kayıt Yapınız</h1>
            <h1 className="text-2xl font-bold mb-4">KAYIT FORMU</h1>
            <h3 className="mb-4">
              Lütfen bilgileri eksiksiz ve doğru doldurunuz.
            </h3>
            <form onSubmit={handleSubmit} className="space-y-4">
              {inputs.map((input) => (
                <div key={input.id} className="flex flex-col">
                  <label className="mb-2 text-lg font-medium">
                    {input.labelName}
                  </label>
                  <input
                    type={input.inputType}
                    name={input.inputName}
                    onChange={handleChange}
                    className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              ))}
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Kayıt Ol
              </button>
            </form>
          </div>
        )} */}

        {showCourtList && <CourtsList phoneNumber={phoneNumber} />}
      </div>
    </div>
  );
}

export default Home;

import React from "react";
import { MdError } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function Error() {
   const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col gap-5 items-center mt-40">
        <MdError className="text-6xl" color="red" />
        <div className="text-2xl">
          Bir Şeyler Ters Gitti Lütfen Tekrar Deneyiniz!
        </div>
        <div className="text-blue-500 cursor-pointer" onClick={()=> navigate('/')}>
            Ana sayfaya dönmek için tıklayınız..
        </div>
      </div>
    </div>
  );
}

export default Error;

import React, { useState, useEffect } from "react";
import axios from "axios";


const Modal = ({ isOpen, onClose, pastAppointments }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-5 rounded-md w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 relative">
        <h2 className="text-xl sm:text-2xl font-bold mb-4 text-center">Geçmiş Randevular</h2>
        <button onClick={onClose} className="absolute top-2 right-2 text-lg sm:text-xl font-bold">
          X
        </button>

        <div className="flex flex-col gap-5 mt-5 overflow-auto h-64">
          {pastAppointments && pastAppointments.length > 0 ? (
            pastAppointments
              .slice()
              .reverse()
              .map((appointment) => {
                const maskedName = `${appointment.name.slice(0, 3)}${"*".repeat(
                  appointment.name.length - 3
                )}`;
                const maskedSurname = `${appointment.surname.slice(0, 3)}${"*".repeat(
                  appointment.surname.length - 3
                )}`;
                const maskedPhoneNumber = `${"*".repeat(
                  appointment.phoneNumber.length - 4
                )}${appointment.phoneNumber.slice(-4)}`;
                return (
                  <div
                    key={appointment.id}
                    className="flex flex-col sm:flex-row bg-gray-100 p-4 rounded-md border border-gray-200 shadow-sm"
                  >
                    <div className="flex flex-col gap-1 items-center sm:items-start flex-1 mb-4 sm:mb-0">
                      <div className="font-bold text-xs sm:text-sm">Ad Soyad</div>
                      <div className="text-xs sm:text-sm">
                        {maskedName} {maskedSurname}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 items-center sm:items-start flex-1 mb-4 sm:mb-0">
                      <div className="font-bold text-xs sm:text-sm">Telefon Numarası</div>
                      <div className="text-xs sm:text-sm">{maskedPhoneNumber}</div>
                    </div>
                    <div className="flex flex-col gap-1 items-center sm:items-start flex-1 mb-4 sm:mb-0">
                      <div className="font-bold text-xs sm:text-sm">Kort Adı</div>
                      <div className="text-xs sm:text-sm">{appointment.courtName}</div>
                    </div>
                    <div className="flex flex-col gap-1 items-center sm:items-start flex-1 mb-4 sm:mb-0">
                      <div className="font-bold text-xs sm:text-sm">Randevu Saati</div>
                      <div className="text-xs sm:text-sm">{appointment.courtTime.slice(0, 5)}</div>
                    </div>
                  </div>
                );
              })
          ) : (
            <div className="text-center text-sm text-gray-500">Geçmiş randevu bulunmamaktadır.</div>
          )}
        </div>
      </div>
    </div>
  );
};


function AppointmentsList() {
  const [courts, setCourts] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchCourts = async () => {
      try {
        const response = await axios.get("https://urlarandevubackend.bilcom.com.tr/api/courts");
        setCourts(response.data.data);
      } catch (error) {
        console.log("Error", error);
      }
    };
    fetchCourts();
  }, []);

  const handleCourtChange = async (e) => {
    const courtName = e.target.value;
    setSelectedCourt(courtName);

    try {
      const courtDay = new Date().toLocaleDateString("tr-TR", {
        weekday: "long",
      });
      console.log("kortumuzun adı", courtName);
      console.log("kortun günü", courtDay);
      const response = await axios.get(
        `https://urlarandevubackend.bilcom.com.tr/api/appointmentsForCourtOfficer?courtName=${courtName}&courtDay=${courtDay}`
      );
      console.log(response.data);
      setAppointments(response.data || []);
    } catch (error) {
      console.log("Error", error);
    }
  };
  const fetchPastAppointments = async (courtName) => {
    const courtDay = new Date().toLocaleDateString("tr-TR", {
      weekday: "long",
    });
    try {
      const response = await axios.get(
        `https://urlarandevubackend.bilcom.com.tr/api/past-appointments?filters[courtName][$eq]=${courtName}&filters[courtDay][$eq]=${courtDay}`
      );
      setPastAppointments(response.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleOpenModal = () => {
    fetchPastAppointments(selectedCourt);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNoShowAndAddBlackList = async (appointment) => {
    console.log("blackliste alınacak", appointment);
    try {
      await axios.post("https://urlarandevubackend.bilcom.com.tr/api/black-lists", {
        data: {
          name: appointment.name,
          surname: appointment.surname,
          phoneNumber: appointment.phoneNumber,
          email: appointment.email,
          appointmentId: appointment.id,
        },
      });
      await axios.delete(
        `https://urlarandevubackend.bilcom.com.tr/api/deleteAppointment?id=${appointment.id}`
      );
      setAppointments(appointments.filter((a) => a.id !== appointment.id));
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <div className="mt-7 p-5 bg-layoutBackground bg-opacity-80 rounded-md">
      <div className="flex flex-col sm:flex-row justify-between sm:items-center">
        <div className="text-xl sm:text-2xl font-bold mb-4 sm:mb-0">
          RANDEVU FORMU
        </div>
        <button
          onClick={handleOpenModal}
          className="px-3 py-1 text-xs sm:text-sm sm:px-4 sm:py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Geçmiş Randevular
        </button>
      </div>

      <div className="flex flex-col mt-5">
        <label className="mb-2 text-base sm:text-lg font-medium">
          Bugün Hangi Kortta Görevlisiniz?
        </label>
        <select
          className="p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={selectedCourt}
          onChange={handleCourtChange}
        >
          <option value="">Kort Seçin</option>
          {courts.map((court) => (
            <option key={court.id} value={court.courtName}>
              {court.courtName}
            </option>
          ))}
        </select>
      </div>

      {appointments.length > 0 && (
        <div className="flex flex-col gap-5 mt-5">
          {appointments.map((appointment) => {
            const maskedName = `${appointment.name.slice(0, 3)}${"*".repeat(
              appointment.name.length - 3
            )}`;
            const maskedSurname = `${appointment.surname.slice(
              0,
              3
            )}${"*".repeat(appointment.surname.length - 3)}`;
            const maskedPhoneNumber = `${"*".repeat(
              appointment.phoneNumber.length - 4
            )}${appointment.phoneNumber.slice(-4)}`;

            return (
              <div
                key={appointment.id}
                className="flex flex-col sm:flex-row bg-layoutBackground rounded-md p-4 sm:p-2"
              >
                <div className="flex flex-col gap-2 items-center flex-1 mb-4 sm:mb-0">
                  <div className="font-bold text-sm sm:text-base">Ad Soyad</div>
                  <div className="text-xs sm:text-sm">
                    {maskedName} {maskedSurname}
                  </div>
                </div>

                <div className="flex flex-col gap-2 items-center flex-1 mb-4 sm:mb-0">
                  <div className="font-bold text-sm sm:text-base">
                    Telefon Numarası
                  </div>
                  <div className="text-xs sm:text-sm">{maskedPhoneNumber}</div>
                </div>

                <div className="flex flex-col gap-2 items-center flex-1 mb-4 sm:mb-0">
                  <div className="font-bold text-sm sm:text-base">Kort Adı</div>
                  <div className="text-xs sm:text-sm">
                    {appointment.courtName}
                  </div>
                </div>

                <div className="flex flex-col gap-2 items-center flex-1 mb-4 sm:mb-0">
                  <div className="font-bold text-sm sm:text-base">
                    Randevu Saati
                  </div>
                  <div className="text-xs sm:text-sm">
                    {appointment.courtTime.slice(0, 5)}
                  </div>
                </div>

                <button
                  type="submit"
                  className="p-1 text-xs sm:text-sm bg-red-500 text-white rounded-md hover:bg-red-600 sm:ml-2"
                  onClick={() => handleNoShowAndAddBlackList(appointment)}
                >
                  Gelmedi
                </button>
              </div>
            );
          })}
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        pastAppointments={pastAppointments}
      />
    </div>
  );
}
export default AppointmentsList;

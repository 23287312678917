import React from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const CancelAppointment = ({ appointments, setAppointments }) => {
  const handleCancel = async (appointmentId) => {
    try {
      await axios.delete(`https://urlarandevubackend.bilcom.com.tr/api/deleteAppointment?id=${appointmentId}`);
      Swal.fire({
        icon: "success",
        title: "Başarılı",
        text: "Randevunuz başarıyla iptal edildi.",
      });
      // alert('Randevu başarıyla iptal edildi.');
      setAppointments(appointments.filter(appointment=>appointment.id !== appointmentId))
    } catch (error) {
      console.error('Randevu iptal edilirken hata oluştu:', error);
    }
  };

  return (
    <div className="mt-7 p-5 bg-layoutBackground bg-opacity-80 rounded-md">
      <h2 className="text-2xl font-bold mb-4">Randevularınızı İptal Edin</h2>
      {appointments.map((appointment) => (
        <div key={appointment.id} className="flex flex-col mb-2">
          <div><strong>Kort Adı:</strong> {appointment.courtName}</div>
          <div><strong>Günü:</strong> {appointment.courtDay}</div>
          <div><strong>Randevu Saati:</strong> {appointment.courtTime.slice(0, 5)}</div>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 mt-2"
            onClick={() => handleCancel(appointment.id)}
          >
            İptal Et
          </button>
        </div>
      ))}
    </div>
  );
};

export default CancelAppointment;
